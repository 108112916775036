.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-uced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

* {
  box-sizing: border-box;
}

body {
  font-family: Arial, Helvetica, sans-serif;
}

/* Style the header */
.mainheader {
  background-color: #f1f1f1;
  padding: 30px;
  text-align: center;
  font-size: 35px;
  height:200px;
  background-image: url("../images/header.jpg");
  background-size: cover;
}
.welcome-message{
	
}

.box {
  margin: 50px auto;
  background: #00bfb6;
  padding: 20px;
  text-align: center;
  font-weight: 500;
  color: #fff;
  font-family: 'lora', serif;
  position:relative;
  font-size: 1.75em;
  line-height:200%;
}

.sbleft:before {

  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 40px solid transparent;
  border-right: 40px solid #00bfb6;
  border-top: 40px solid #00bfb6;
  border-bottom: 40px solid transparent;
  left: -60px;
  top: 20px;
}

.sbright:before {

  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 40px solid #00bfb6;
  border-right: 40px solid transparent;
  border-top: 40px solid #00bfb6;
  border-bottom: 40px solid transparent;
  right: -60px;
  top: 30px;
}

.sbblue:before {
  border-left: 40px solid #0099ff;
  border-top: 40px solid #0099ff;
}

.box-blue {
	background-color:#0099ff;
}

.box-green {
	background-color:#00cc00;
}
.box-purple {
	background-color:#9933ff;
}
.sbpurple:before {
  border-left: 40px solid #9933ff;
  border-top: 40px solid #9933ff;
}
.sbgreen:before {
  border-right: 40px solid #00cc00;
  border-top: 40px solid #00cc00;
}

.avatar-timmy {
  height:300px;
  margin: auto;
  width: 150px;
  padding: 70px 0 70px 0;
  background-image: url("../images/avatar large - edit.png"); 
  background-size: contain;
   background-repeat: no-repeat;
}

.avatar-dad {
  height:300px;
  margin: auto;
  width: 150px;
  padding: 70px 0 70px 0;
  background-image: url("../images/avatar-dad-large.png"); 
  background-size: contain;
   background-repeat: no-repeat;
}

.avatar-daniel {
  height:300px;
  margin: auto;
  width: 150px;
  padding: 70px 0 70px 0;
  background-image: url("../images/avatar-daniel-large.png"); 
  background-size: contain;
   background-repeat: no-repeat;
}

.mega-houndoom {
	
  height:400px;
  margin: auto;
  padding: 70px 0 70px 0;
  background-image: url("../images/pokemon/mega-houndoom.png"); 
  background-size: contain;
   background-repeat: no-repeat;
}

.mega-houndoom {
	
  height:400px;
  margin: auto;
  padding: 70px 0 70px 0;
  background-image: url("../images/pokemon/mega-houndoom.png"); 
  background-size: contain;
   background-repeat: no-repeat;
}


.avatar-puppy {
  height:300px;
  margin: auto;
  width: 150px;
  padding: 70px 0 70px 0;
  background-image: url("../images/puppy.png"); 
  background-size: contain;
   background-repeat: no-repeat;
}

.avatar-mummy {
  height:300px;
  margin: auto;
  width: 150px;
  padding: 70px 0 70px 0;
  background-image: url("../images/avatar-mummy-large.png"); 
  background-size: contain;
   background-repeat: no-repeat;
}
/* Create three unequal columns that floats next to each other */
.column {
  float: left;
  padding: 10px;
}

/* Left and right column */
.column.side {
  width: 25%;
}

/* Middle column */
.column.middle {
  width: 50%;
  min-height:300px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}


.rowdark {
	background-color:#404040;
}

/* Style the footer */
.footer {
  background-color: #f1f1f1;
  padding: 10px;
  text-align: center;
  height: 200px;
  position: relative;
}

/* Responsive layout - makes the three columns stack on top of each other instead of next to each other */
@media (max-width: 600px) {
  .column.side, .column.middle {
    width: 100%;
  }
}

.main {
  text-align: center;
}

#container{ 
	min-height: 100%;
	margin-bottom: -330px;
	position: relative;
}